const logging = require('logging');

const Backbone = require('Backbone');
const TenantPropertyProvider = require('@common/services/TenantPropertyProvider');
const UserMetadata = require('@common/data/models/UserMetadata');

const I18n = require('@common/libs/I18n');

const {
  getQueryParams,
  parseStringId
} = require('@common/libs/helpers/app/UrlHelpers');

const {
  SEARCH,
  TOPIC_DETAILS,
  ARTICLE_DETAILS,
  ARTICLE_ATTACHMENT,
  ARTICLE_EDIT,
  ARTICLE_CREATE,
  ARTICLE_HISTORY,
  COMMUNITIES,
  IMPORT_HISTORY,
  COMMUNITY_MANAGEMENT,
  PAGE_INSIGHTS,
  FILTERS,
  SETTINGS,
  COMMUNITIES_INSIGHTS,
  COMMUNITIES_INSIGHTS_REPORTS,
  PROFANITY_FILTER,
  TAGS
} = require('@common/data/enums/SearchPageEnum');

const {
  HUB,
  ACHIEVEMENTS,
  REFERRAL,
  CERTIFICATION_TRAINING,
  INTRO_TRAINING,
  REFRESHER_TRAINING,
  FORMAL_EXAM,
  REWARDS,
  GAME_SCORES,
  GAME_SCORES_DETAILS,
  REPORT_CARD,
  TOP_PERFORMERS,
  FEED,
  LEARNING_EVENTS,
  LEARNING_EVENT_DETAILS,
  PROFILE,
  MESSAGE,
  GUIDED_LEARNING,
  SELF_DIRECTED_PATHS,
  LEARNER_TRAINING,
  TIMELINE,
  CONVERSATIONAL_SEARCH,
  EXECUTION_TASKS,
  CAMPAIGN_COMMUNICATIONS
} = require('@common/data/enums/HubTileIdentifiers').default;

const GuidedLearningPageEnum = require('@common/data/enums/GuidedLearningPageEnum');

const SearchCategoryEnum = require('@training/apps/training/enums/SearchCategoryEnum');
const SearchSubCategoryEnum = require('@training/apps/training/enums/SearchSubCategoryEnum');
const CatalogBrowseTypeEnum = require('@training/apps/learnerTraining/learnerTrainingTab/CatalogBrowseTypeEnum');

const { downloadCertification } = require('@training/apps/topics/printCertifications/PrintCertificationDownloadHelpers');

class TrainingRouter extends Backbone.Router {

  routes() {
    return {
      'eventDetails/:eventParams(/)(:tabName)': 'eventDetails',

      // Flow
      'games(/)': 'gameSelect',
      'games/:gameId': 'gameInfo',
      'games/:gameId/challenge': 'gameChallengeSelection',
      'games/:gameId/challenge/:challengeId': 'gameInfo',

      'guided/:programId/topicDetails/:topicId': 'guidedTopicDetailsPage',
      'guided/:programId/taskDetails/:programId-:taskId': 'guidedTaskDetailsPage',
      'guided/:programId/events/:eventId': 'guidedFilteredEventPage',
      'guided/:programId/eventDetails/:eventParams(/)(:tabName)': 'guidedLearningEventDetails',
      'guided/:programId/evaluationDetails/:evaluationId': 'guidedEvaluationDetailsPage',
      'guided/:programId': 'guidedDetailsPage',

      // TODO: Consider removing if a proper view for available communiations/offers is added in the future. Currently
      // this only allows us to capture a nudge deeplink path on the hub and scroll the nudge feed into view
      'hub/nudgelink/:nudgeId': 'hubNudgeDeeplink',

      'hub(/)': this._loadHubPage.bind(this, HUB),

      'hub/achievements(/:tabName)': 'achievementsPage',
      'hub/referral': this._loadHubPage.bind(this, REFERRAL),

      'hub/certifications': this._loadHubPage.bind(this, CERTIFICATION_TRAINING),
      'hub/newTopics': this._loadHubPage.bind(this, INTRO_TRAINING),
      'hub/refresher': this._loadHubPage.bind(this, REFRESHER_TRAINING),
      'hub/exams': this._loadHubPage.bind(this, FORMAL_EXAM),

      'hub/guided/:programId/topicDetails/:topicId': 'hubGuidedTopicDetailsPage',
      'hub/guided/:programId/taskDetails/:programId-:taskId': 'hubGuidedTaskDetailsPage',
      'hub/guided/:programId/events/:eventId': 'hubGuidedFilteredEventPage',
      'hub/guided/:programId/eventDetails/:eventParams(/)(:tabName)': 'hubGuidedLearningEventDetails',
      'hub/guided/:programId/evaluationDetails/:evaluationId': 'hubGuidedEvaluationDetailsPage',
      'hub/guided/:programId': 'hubGuidedDetailsPage',

      'hub/paths/:programId/topicDetails/:topicId': 'hubSelfDirectedTopicDetailsPage',
      'hub/paths/:programId/taskDetails/:programId-:taskId': 'hubSelfDirectedPathTaskDetailsPage',
      'hub/paths/:programId/events/:eventId': 'hubSelfDirectedPathFilteredEventPage',
      'hub/paths/:programId/eventDetails/:eventParams(/)(:tabName)': 'hubSelfDirectedEventDetails',
      'hub/paths/:programId/evaluationDetails/:evalautionId': 'hubSelfDirectedEvaluationDetailsPage',
      'hub/paths/:programId': 'hubSelfDirectedPathDetailsPage',

      'hub/gameScores(/)': this._loadHubPage.bind(this, GAME_SCORES),
      'hub/gameScores(/:gameId)': 'gameScoresDetails',

      'hub/events(/)(:tabName)': 'learningEvents',
      'hub/eventDetails(/)(:eventParams)(/)(:tabName)': 'learningEventDetails',

      'hub/feed(/)(:feedType)': 'feedPage',
      'hub/message/:messageParams': 'messageDetails',

      'hub/topPerformers(/)(:timePeriod)': 'topPerformers',

      'hub/rewards(/)(:auctionType)': 'rewardsPage',

      'hub/taskList/checklist/:checklistId-:groupId': 'integrationChecklistDetailPage',
      'hub/taskList(/)(:groupId)': 'integrationTasksPage',
      'hub/communication': 'communicationPage',

      'hub/reportCard': this._loadHubPage.bind(this, REPORT_CARD, {
        sessionController: this.sessionController
      }),
      'hub/reportCardDetails(/)(:topicParams)': 'reportCardDetails',

      'hub/training/:tabName/type-:type/:pageNum(/:string)': 'catalogTypeBrowse',
      'hub/training(/)(:tabName)(/:pageNum)(/:string)': 'learnerTrainingPageAccess',
      'hub/extraTraining': 'extraTrainingPageAccess',

      'hub/profile(/)(:settingsTab)': 'profilePages',

      'hub/search/topic/:topicId': 'topicDetailsView',
      'hub/search/topic/:topicId/level/:level/certificate/:language': 'certificateView',
      'hub/search/article/create/new-:articleType(/)(:languageCode)': 'articleCreateView',
      'hub/search/article/:articleId': 'articleDetailsView',
      'hub/search/article/:articleId/history': 'articleEditHistory',
      'hub/search/article/:articleId/edit': 'articleDetailsEditView',
      'hub/search/article/:pageId/insights(/)(team-:teamid)': 'pageInsights',
      'hub/search/article/:articleId/:attachmentId': 'articleAttachmentView',
      'hub/search/articles/tags-:tagList/:pageNum(/)(:string)': 'taggedArticleSearch',
      'hub/search/articles/community-:communityId/communityManagement': 'communityManagement',
      'hub/search/articles/communityManagement/new': 'communityManagement',
      'hub/search/community-:communityId/articles/tags-:tagList(/)(:pageNum)(/)(:string)': 'taggedCommunitySearch',
      'hub/search/community-:communityId(/)(:tabName)(/)(:pageNum)(/)(:string)': 'communitySearch',
      'hub/search/all/community-:communityId(/)': 'allCommunitySearch',
      'hub/search/articles/needs-review(/)(:pageNum)(/)': 'needsReviewResults',
      'hub/search/articles/favorites(/)(:pageNum)(/)': 'favorites',

      'hub/search/communicationsInsights': 'communicationsInsights',
      'hub/search/communicationsInsights-includeTeamBasedCommunities': 'communicationsInsightsIncludeTeamBasedCommunities',
      'hub/search/communicationsInsights/reports': 'communicationsInsightsReports',
      'hub/search/communicationsInsights/reports/(:tabName)': 'communicationsInsightsReports',
      'hub/search/communicationsInsights-includeTeamBasedCommunities/reports/(:tabName)': 'communicationsInsightsReportsIncludeTeamBasedCommunities',

      'hub/search/articles/reports': 'articleReports',
      'hub/search/articles/top_pages': 'reportsTopPages',
      'hub/search/articles/top_keywords_in_search': 'reportsTopKeywords',
      'hub/search/articles/top_keywords_in_search_empty': 'reportsTopKeywordsEmptySearch',
      'hub/search/communitiesManagement/communities(/)(:pageNum)(/)': 'communitiesManagementCommunities',
      'hub/search/communitiesManagement/settings': 'communitiesManagementSettings',
      'hub/search/communitiesManagement/importHistory(/)(:pageNum)(/)': 'communitiesManagementImportHistory',
      'hub/search/communitiesManagement/filters(/)(:filterID)(/)': 'communitiesManagementFilters',
      'hub/search/communitiesManagement/profanityFilter': 'communitiesManagementProfanityFilter',
      'hub/search/communitiesManagement/tags(/)(:pageNum)(/)': 'communitiesManagementTags',

      'hub/search(/)(:string)': 'search',
      'hub/search/:category/:pageNum(/)(:string)': 'categorySearch',

      'hub/timeline(?:params)': 'timelineSearch',

      'hub/max': 'conversationalSearch',

      index: 'index',
      'index?*path': 'index',

      assessmentLink: 'assessmentLink',
      mobileNotification: 'mobileNotification',

      '*action': 'default'
    };
  }

  preinitialize(sessionController) {
    this.sessionController = sessionController;
    this.hasLoadedIndexYet = false;
    this.hubController = this.sessionController.getHubController();
    this.authUser = window.apps.auth.session.user
    this.userMetadata = new UserMetadata();
  }

  index() {
    if (this.hasLoadedIndexYet) {
      this.default();
    } else {
      this.sessionController.loadIndex();
      this.hasLoadedIndexYet = true;
    }
  }

  default () {
    this._loadHubPage(HUB);
  }

  assessmentLink() {
    this.index();
  }

  mobileNotification() {
    // For now, this may not be safe to redirect in the same way we do in SessionController,
    // after a hash change (for example, if an assessment is in progress)
    this.index();
  }

  extraTrainingPageAccess() {
    this.navigate('hub/training/topics', {
      trigger: true,
      replace: true
    });
  }

  learnerTrainingPageAccess(trainingTab, pageNum, queryString, type) {
    if (!TenantPropertyProvider.get().getProperty('trainingCatalogEnabled') && trainingTab === 'catalog') {
      this.navigate('hub/training/topics', {
        trigger: true,
        replace: true
      });
    } else {
      const query = queryString || '';
      this._loadHubPage(LEARNER_TRAINING, {
        initialTab: trainingTab || undefined,
        sessionController: this.sessionController,
        browseType: type || CatalogBrowseTypeEnum.ALL,
        pageNum: parseInt(pageNum, 10) || 0,
        searchQuery: decodeURIComponent(query)
      });
    }
  }

  catalogTypeBrowse(trainingTab, type, pageNum, queryString) {
    this.learnerTrainingPageAccess(trainingTab, pageNum, queryString, type);
  }

  gameSelect() {
    if (!this.sessionController.session.hasCurrentAssessment()) {
      this.default();
      return;
    }

    this.sessionController.getCurrentAssessmentController().getQuestionsController()
      .processActivities();
  }

  gameInfo(gameIdString, challengeIdString) {
    const gameId = parseStringId(gameIdString);
    const challengeId = parseStringId(challengeIdString);

    // Only show the game info page if there's a valid `gameId` or 'challengeId' and there's an
    // assessment created without a game selected
    if ((gameId == null && challengeId == null) || !this.sessionController.session.hasCurrentAssessment()) {
      this.default();
      return;
    }

    const questionsController = this.sessionController.getCurrentAssessmentController().getQuestionsController();

    if (questionsController.canPickGame()) {
      questionsController.showGameInfo(gameId, challengeId);
    } else {
      this.default();
    }
  }

  gameChallengeSelection(gameIdString) {
    const gameId = parseStringId(gameIdString);

    // Show the game challenge selection page if there's a valid `gameId` and
    // there's an assessment created without a game selected
    if (gameId == null || !this.sessionController.session.hasCurrentAssessment()) {
      this.default();
      return;
    }

    const questionsController = this.sessionController.getCurrentAssessmentController().getQuestionsController();

    if (questionsController.canPickGame()) {
      questionsController.showGameChallengeSelection(gameId);
    } else {
      this.default();
    }
  }

  guidedDetailsPage(programIdString) {
    const programId = parseStringId(programIdString);

    this._loadGuidedLearningPage(GuidedLearningPageEnum.DetailsPage, {
      programId
    });
  }

  guidedTaskDetailsPage(programIdString, taskProgramId, taskIdString) {
    const programId = parseStringId(programIdString);
    const taskId = parseStringId(taskIdString);

    this._loadGuidedLearningPage(GuidedLearningPageEnum.TaskDetailsPage, {
      programId,
      taskId
    });
  }

  guidedFilteredEventPage(programIdString, eventIdString) {
    const eventId = parseStringId(eventIdString);
    const programId = parseStringId(programIdString);

    this._loadGuidedLearningPage(GuidedLearningPageEnum.EventSessionsPage, {
      programId,
      eventId
    });
  }

  guidedLearningEventDetails(programIdString, eventParamsString, initialTabString) {
    const programId = parseStringId(programIdString);

    const [eventIdString, scheduledEventIdString, enrollmentIdString] = eventParamsString.split('-');

    const eventId = parseStringId(eventIdString);
    const scheduledEventId = parseStringId(scheduledEventIdString);
    const enrollmentId = parseStringId(enrollmentIdString);
    const initialTab = initialTabString || undefined;

    this._loadGuidedLearningPage(GuidedLearningPageEnum.LearningEventDetailsPage, {
      programId,
      eventId,
      scheduledEventId,
      enrollmentId,
      initialTab
    });
  }

  guidedEvaluationDetailsPage(programIdString, evaluationIdString) {
    const evaluationId = parseStringId(evaluationIdString);
    const programId = parseStringId(programIdString);

    this._loadGuidedLearningPage(GuidedLearningPageEnum.EvaluationDetailsPage, {
      programId,
      evaluationId
    });
  }

  hubGuidedDetailsPage(programIdString) {
    const programId = parseStringId(programIdString);

    this._loadHubGuidedLearningPage(GuidedLearningPageEnum.DetailsPage, {
      programId
    });
  }

  guidedTopicDetailsPage(programIdString, topicIdString) {
    const programId = parseInt(programIdString, 10) || undefined;
    const topicId = parseInt(topicIdString, 10) || undefined;

    this._loadGuidedLearningPage(GuidedLearningPageEnum.TopicDetailsPage, {
      programId,
      topicId
    });
  }

  hubGuidedTopicDetailsPage(programIdString, topicIdString) {
    const programId = parseInt(programIdString, 10) || undefined;
    const topicId = parseInt(topicIdString, 10) || undefined;

    this._loadHubGuidedLearningPage(GuidedLearningPageEnum.TopicDetailsPage, {
      programId,
      topicId
    });
  }

  hubSelfDirectedTopicDetailsPage(programIdString, topicIdString) {
    const programId = parseInt(programIdString, 10) || undefined;
    const topicId = parseInt(topicIdString, 10) || undefined;

    this._loadHubSelfDirectedPathPage(GuidedLearningPageEnum.TopicDetailsPage, {
      programId,
      topicId
    });
  }

  hubGuidedTaskDetailsPage(programIdString, taskProgramId, taskIdString) {
    const programId = parseStringId(programIdString);
    const taskId = parseStringId(taskIdString);

    this._loadHubGuidedLearningPage(GuidedLearningPageEnum.TaskDetailsPage, {
      programId,
      taskId
    });
  }

  hubGuidedFilteredEventPage(programIdString, eventIdString) {
    const eventId = parseStringId(eventIdString);
    const programId = parseStringId(programIdString);

    this._loadHubGuidedLearningPage(GuidedLearningPageEnum.EventSessionsPage, {
      programId,
      eventId
    });
  }

  hubGuidedLearningEventDetails(programIdString, eventParamsString, initialTabString) {
    const programId = parseStringId(programIdString);

    const [eventIdString, scheduledEventIdString, enrollmentIdString] = eventParamsString.split('-');

    const eventId = parseStringId(eventIdString);
    const scheduledEventId = parseStringId(scheduledEventIdString);
    const enrollmentId = parseStringId(enrollmentIdString);
    const initialTab = initialTabString || undefined;

    this._loadHubGuidedLearningPage(GuidedLearningPageEnum.LearningEventDetailsPage, {
      programId,
      eventId,
      scheduledEventId,
      enrollmentId,
      initialTab
    });
  }

  hubGuidedEvaluationDetailsPage(programIdString, evaluationIdString) {
    const evaluationId = parseStringId(evaluationIdString);
    const programId = parseStringId(programIdString);

    this._loadHubGuidedLearningPage(GuidedLearningPageEnum.EvaluationDetailsPage, {
      programId,
      evaluationId
    });
  }

  hubSelfDirectedPathDetailsPage(programIdString) {
    const programId = parseStringId(programIdString);

    this._loadHubSelfDirectedPathPage(GuidedLearningPageEnum.DetailsPage, {
      programId
    });
  }

  hubSelfDirectedPathTaskDetailsPage(programIdString, taskProgramId, taskIdString) {
    const programId = parseStringId(programIdString);
    const taskId = parseStringId(taskIdString);

    this._loadHubSelfDirectedPathPage(GuidedLearningPageEnum.TaskDetailsPage, {
      programId,
      taskId
    });
  }

  hubSelfDirectedPathFilteredEventPage(programIdString, eventIdString) {
    const eventId = parseStringId(eventIdString);
    const programId = parseStringId(programIdString);

    this._loadHubSelfDirectedPathPage(GuidedLearningPageEnum.EventSessionsPage, {
      programId,
      eventId
    });
  }


  hubSelfDirectedEventDetails(programIdString, eventParamsString, initialTabString) {
    const programId = parseStringId(programIdString);

    const [eventIdString, scheduledEventIdString, enrollmentIdString] = eventParamsString.split('-');

    const eventId = parseStringId(eventIdString);
    const scheduledEventId = parseStringId(scheduledEventIdString);
    const enrollmentId = parseStringId(enrollmentIdString);
    const initialTab = initialTabString || undefined;

    this._loadHubSelfDirectedPathPage(GuidedLearningPageEnum.LearningEventDetailsPage, {
      programId,
      eventId,
      scheduledEventId,
      enrollmentId,
      initialTab
    });
  }

  hubSelfDirectedEvaluationDetailsPage(programIdString, evaluationIdString) {
    const evaluationId = parseStringId(evaluationIdString);
    const programId = parseStringId(programIdString);

    this._loadHubSelfDirectedPathPage(GuidedLearningPageEnum.EvaluationDetailsPage, {
      programId,
      evaluationId
    });
  }

  hubNudgeDeeplink(nudgeIdString) {
    const nudgeId = parseStringId(nudgeIdString);

    // Replace url path with #hub to prevent scrolling on a browser back or refresh
    Backbone.history.navigate('hub', { replace: true });

    this._loadHubPage(HUB, { nudgeId });
  }

  buildHubRouteHandler(hubIdentifier, pageOptions) {
    return () => {
      this._loadHubPage(hubIdentifier, pageOptions);
    };
  }

  _loadHubPage(pageId, options = {}) {
    const pageOptions = Object.assign({
      navigate: false
    }, options);

    this.sessionController.loadHub(Object.assign({
      pageId,
      pageOptions
    }));
  }

  _loadGuidedLearningPage(pageId, options = {}) {
    const pageOptions = Object.assign({
      navigate: false
    }, options);

    const hash = window.location.hash;
    if (this.sessionController.isHubMenuEnabled() && !hash.includes('hub')) {
      Backbone.history.navigate('#hub/' + hash.substring(1), {
        replace: true
      });
    }

    this.sessionController.getGuidedLearningController().showPageFlow({
      pageId,
      pageOptions
    })
      .catch(() => {
        // pre-training GL route must now explicitly call session controller's flow to dump user back
        this._loadHubPage(GUIDED_LEARNING, {
          pageId,
          pageOptions
        });
      });
  }

  _loadSelfDirectedPathPage(pageId, options = {}) {
    const pageOptions = Object.assign({
      navigate: false
    }, options);

    this.sessionController.getSelfDirectedPathsController().showPageFlow({
      pageId,
      pageOptions
    })
      .catch(() => {
        // all other errors dump the user back up to the session controllers flow
      });
  }

  _loadHubGuidedLearningPage(pageId, options = {}) {
    const pageOptions = Object.assign({
      navigate: false
    }, options);
    this._loadHubPage(GUIDED_LEARNING, {

      pageId,

      pageOptions

    });
  }

  _loadHubSelfDirectedPathPage(pageId, options = {}) {
    const pageOptions = Object.assign({ navigate: false }, options);
    this._loadHubPage(SELF_DIRECTED_PATHS, {
      pageId,
      pageOptions
    });
  }

  gameScoresDetails(gameIdString) {
    const gameId = parseStringId(gameIdString);

    if (gameId == null) {
      logging.error(`gameScoresDetails route: \`${ gameIdString }\` is not a valid game id`);
      // eslint-disable-next-line no-unused-expressions
      this.game;
      this.navigate('hub/gameScores', {
        trigger: true,
        replace: true
      });
      return;
    }

    this._loadHubPage(GAME_SCORES_DETAILS, {
      gameId
    });
  }

  eventDetails(eventParamsString, initialTabString) {
    const [
      eventIdString,
      scheduledEventIdString,
      enrollmentIdString
    ] = eventParamsString.split('-');

    const eventId = parseStringId(eventIdString);
    const scheduledEventId = parseStringId(scheduledEventIdString);
    const enrollmentId = parseStringId(enrollmentIdString);
    const initialTab = initialTabString || undefined;

    if (eventId == null || scheduledEventId == null) {
      logging.error(`learningeventsDetails route: \`${ eventId }\` or \`${ scheduledEventId }\` is not a valid id`);
      this.navigate('index', {
        trigger: true,
        replace: true
      });
      return;
    }

    const LearningEventDetailsPage = require('@training/apps/training/views/LearningEventDetailsPage');
    const detailsPage = new LearningEventDetailsPage({
      scheduledEventId,
      eventId,
      enrollmentId,
      initialTab
    });

    window.app.layout.setView(detailsPage);
  }

  learningEventDetails(eventParamsString, initialTabString) {
    const [
      eventIdString,
      scheduledEventIdString,
      enrollmentIdString
    ] = eventParamsString.split('-');

    const eventId = parseStringId(eventIdString);
    const scheduledEventId = parseStringId(scheduledEventIdString);
    const enrollmentId = parseStringId(enrollmentIdString);
    const initialTab = initialTabString || undefined;

    if (eventId == null || scheduledEventId == null) {
      logging.error(`learningeventsDetails route: \`${ eventId }\` or \`${ scheduledEventId }\` is not a valid id`);
      this.navigate('hub/events', {
        trigger: true,
        replace: true
      });
      return;
    }

    const eventEnrollmentRecordCallback = () => {
      return this.sessionController.loadSession();
    };

    this._loadHubPage(LEARNING_EVENT_DETAILS, {
      scheduledEventId,
      eventId,
      enrollmentId,
      initialTab,
      routePrefix: 'hub',
      eventEnrollmentRecordCallback
    });
  }

  learningEvents(initialTabString) {
    this._loadHubPage(LEARNING_EVENTS, {
      initialTab: initialTabString || undefined
    });
  }

  messageDetails(paramsString) {
    const [messageIdString, languageString] = paramsString.split('-');
    const messageId = parseStringId(messageIdString);
    const language = languageString || undefined;

    if (messageId == null || language == null) {
      logging.error('messageDetails route: provided \'messageId\' or \'language\' are not valid');
      this.navigate('hub/feed/messages', {
        trigger: true,
        replace: true
      });
      return;
    }

    this._loadHubPage(MESSAGE, {
      messageId,
      language
    });
  }

  feedPage(feedType) {
    if (this.hubController.isConsolidatedCommsApplicable() && feedType === 'messages') {
      this.navigate('hub/feed', {
        trigger: true,
        replace: true
      });
      return;
    }

    this._loadHubPage(FEED, {
      initialTab: feedType || undefined
    });
  }

  topPerformers(timePeriodString) {
    this._loadHubPage(TOP_PERFORMERS, {
      initialTab: timePeriodString || undefined
    });
  }

  achievementsPage(tabName) {
    this._loadHubPage(ACHIEVEMENTS, {
      initialTab: tabName ?? undefined
    });
  }

  rewardsPage(auctionType) {
    this._loadHubPage(REWARDS, {
      initialTab: auctionType || undefined
    });
  }

  integrationTasksPage(groupIdString) {
    if (!this.hubController.isHubTileEnabled(EXECUTION_TASKS)) {
      this.navigate('hub', {
        trigger: true,
        replace: true
      });
      return;
    }

    if (TenantPropertyProvider.get().getProperty('tasksEnabled')) {
      window.app.layout.setView(
        require('@common/modules/react').ReactControllerDefinitionFactory({
          component: import('@training/apps/opsTasks/TaskViewLayout'),
          props: {
            groupId: parseStringId(groupIdString),
            enableBreadcrumbs: true,
            enableDateHeaders: true
          }
        })
      );
    } else {
      window.app.layout.setView(
        require('@common/modules/react').ReactControllerDefinitionFactory({
          component: import('@training/apps/tasks/TaskViewLayout'),
          props: {
            groupId: parseStringId(groupIdString),
            enableBreadcrumbs: true,
            enableDateHeaders: true
          }
        })
      );
    }
  }

  integrationChecklistDetailPage(checklistIdString, groupIdString) {
    const groupId = parseStringId(groupIdString);
    const checklistId = parseStringId(checklistIdString);

    if (
      !TenantPropertyProvider.get().getProperty('integrationBetaFeaturesEnabled')
      || !TenantPropertyProvider.get().getProperty('executionPlatformEnabled')
      || groupId === undefined
      || checklistId === undefined
    ) {
      this.navigate('hub', {
        trigger: true,
        replace: true
      });
      return;
    }

    if (!TenantPropertyProvider.get().getProperty('tasksEnabled')) {
      window.app.layout.setView(
        require('@common/modules/react').ReactControllerDefinitionFactory({
          component: import('@training/apps/tasks/checklists/ChecklistViewLayout'),
          props: {
            checklistId,
            groupId
          }
        })
      );
    }
  }

  communicationPage() {
    if (!this.hubController.isHubTileEnabled(CAMPAIGN_COMMUNICATIONS)) {
      this.navigate('hub', {
        trigger: true,
        replace: true
      });
      return;
    }

    window.app.layout.setView(
      require('@common/modules/react').ReactControllerDefinitionFactory({
        component: import('@training/apps/nudges/communication/CommunicationView')
      })
    );
  }

  reportCardDetails(paramsString) {
    const [topicIdString, levelString] = paramsString.split('-');
    const topicId = parseStringId(topicIdString);
    const level = parseStringId(levelString);

    if (topicId == null || level == null) {
      logging.error('topicKnowlegeHistory route: provided \'topicId\' or \'level\' are not valid');
      this.navigate('hub/reportCard', {
        trigger: true,
        replace: true
      });
      return;
    }

    this._loadHubPage(REPORT_CARD, {
      sessionController: this.sessionController,
      showDetails: true,
      topicId,
      level
    });
  }

  profilePages(profileTab) {
    this._loadHubPage(PROFILE, {
      initialTab: profileTab
    });
  }

  topicDetailsView(topicIdString) {
    const topicId = parseStringId(topicIdString);

    if (topicId == null) {
      window.app.layout.flash.error(I18n.t('selfDirected.topicDetails.errors.notAvailable', { topicId }));
      this.navigate('hub/search/topics/1', {
        trigger: true,
        replace: true
      });
      return;
    }

    const pageOptions = {
      topicId
    };
    this._loadHubPageWithData(SearchCategoryEnum.TOPICS, TOPIC_DETAILS, pageOptions);
  }

  certificateView(topicId, level, language) {
    if (!TenantPropertyProvider.get().getProperty('isPrintCertificationsEnabled')) {
      this.navigate('#', true);
    } else {
      downloadCertification({
        topicId,
        level,
        language: language,
        userType: window.apps.auth.session.user.get('userType')
      });
    }
  }

  articleDetailsView(articleIdString) {
    const articleId = this._isArticleIdNull(articleIdString);

    if (!articleId) {
      return;
    }

    const queryParams = getQueryParams(window.location.hash);

    const pageOptions = {
      articleId,
      queryParams
    };
    this._loadHubPageWithData(SearchCategoryEnum.ARTICLES, ARTICLE_DETAILS, pageOptions);
  }

  articleEditHistory(articleIdString) {
    const articleId = this._isArticleIdNull(articleIdString);

    if (!articleId) {
      return;
    }

    const pageOptions = {
      articleId
    };
    this._loadHubPageWithData(SearchCategoryEnum.ARTICLES, ARTICLE_HISTORY, pageOptions);
  }

  articleAttachmentView(articleIdString, attachmentIdString) {
    const articleId = this._isArticleIdNull(articleIdString);

    if (!articleId) {
      return;
    }

    const attachmentId = parseStringId(attachmentIdString);

    if (attachmentId == null) {
      window.app.layout.flash.error(I18n.t('discover.pageTypes.fetch.error'));
      this.navigate(`hub/search/article/${ articleId }`, {

        trigger: true,

        replace: true

      });
      return;
    }

    const pageOptions = {
      articleId,
      attachmentId
    };
    this._loadHubPageWithData(SearchCategoryEnum.ARTICLES, ARTICLE_ATTACHMENT, pageOptions);
  }

  articleDetailsEditView(articleIdString) {
    const articleId = this._isArticleIdNull(articleIdString);

    if (!articleId) {
      return;
    }

    const pageOptions = {
      articleId
    };
    this._loadHubPageWithData(SearchCategoryEnum.ARTICLES, ARTICLE_EDIT, pageOptions);
  }

  articleCreateView(pageType = {}, languageCode = null) {
    const pageOptions = {
      pageType,
      languageCode
    };
    this._loadHubPageWithData(SearchCategoryEnum.ARTICLES, ARTICLE_CREATE, pageOptions);
  }


  search(searchString) {
    const queryString = searchString || '';

    this.hubController.getDiscoverAccessState()
      .then(() => {
        if (this._checkSearchAccessRedirect()) {
          return;
        }

        this._loadHubPage(SEARCH, {
          queryString
        });
      });
  }

  taggedArticleSearch(tagListString, pageNumString, queryString) {
    const searchString = queryString || '';
    const tagList = tagListString || '';
    const pageNum = parseStringId(pageNumString);


    const pageOptions = {
      searchCategory: SearchCategoryEnum.ARTICLES,
      searchString,
      pageNum,
      tagList
    };
    this._loadHubPageWithData(SearchCategoryEnum.ARTICLES, SEARCH, pageOptions);
  }

  categorySearch(searchCategory, pageNumString, queryString) {
    const searchString = queryString || '';
    const pageNum = parseStringId(pageNumString);

    const pageOptions = {
      searchCategory,
      searchString,
      pageNum
    };

    if (searchCategory.startsWith('type-')) {
      pageOptions.searchCategory = SearchCategoryEnum.ARTICLES;
      pageOptions.communityType = searchCategory.replace('type-', '')
    }

    this._loadHubPageWithData(searchCategory, SEARCH, pageOptions);
  }

  _baseCommunitySearch(options = {}) {
    const pageOptions = Object.assign({
      searchCategory: SearchCategoryEnum.ARTICLES,
      subCategory: SearchSubCategoryEnum.COMMUNITY,
      tabName: SearchCategoryEnum.ARTICLES
    }, options);

    this._loadHubPageWithData(SearchCategoryEnum.ARTICLES, SEARCH, pageOptions);
  }

  taggedCommunitySearch(communityIdString, tagListString, pageNumString, queryString) {
    const searchString = queryString || '';
    const communityId = parseStringId(communityIdString);
    const tagList = tagListString || '';
    const pageNum = parseStringId(pageNumString);

    this._baseCommunitySearch({
      searchString,
      communityId,
      tagList,
      pageNum
    });
  }

  communitySearch(communityIdString, tabName, pageNumString, queryString) {
    const searchString = queryString || '';
    const communityId = parseStringId(communityIdString);
    const pageNum = parseStringId(pageNumString);

    this._baseCommunitySearch({
      searchString,
      communityId,
      pageNum,
      tabName
    });
  }

  allCommunitySearch(communityIdString, pageNumString, queryString) {
    const searchString = queryString || '';
    const communityId = parseStringId(communityIdString);
    const pageNum = parseStringId(pageNumString);
    const searchCategory = SearchCategoryEnum.ALL;

    this._baseCommunitySearch({
      searchCategory,
      searchString,
      communityId,
      pageNum
    });
  }

  needsReviewResults(pageNumString) {
    const pageNum = parseInt(pageNumString, 10) || undefined;

    this.hubController.getDiscoverAccessState()
      .then(() => {
        if (this._checkSearchAccessRedirect(SearchCategoryEnum.ARTICLES)) {
          return;
        }

        this._loadHubPage(SEARCH, {
          searchCategory: SearchCategoryEnum.ARTICLES,
          subCategory: SearchSubCategoryEnum.PENDING,
          pageNum
        });
      });
  }

  favorites(pageNumString) {
    const pageNum = parseInt(pageNumString, 10) || undefined;

    this.hubController.getDiscoverAccessState()
      .then(() => {
        if (this._checkSearchAccessRedirect(SearchCategoryEnum.ARTICLES)) {
          return;
        }

        this._loadHubPage(SEARCH, {
          searchCategory: SearchCategoryEnum.ARTICLES,
          subCategory: SearchSubCategoryEnum.FAVORITES,
          pageNum
        });
      });
  }

  articleReports() {
    Promise.all([
      this._fetchUserMetadata(),
      this.hubController.getDiscoverAccessState()
    ]).then(() => {
      if (this._checkSearchAccessRedirect(SearchCategoryEnum.ARTICLES, SearchSubCategoryEnum.ARTICLE_REPORTS)) {
        return;
      }

      this._loadHubPage(SEARCH, {
        searchCategory: SearchCategoryEnum.ARTICLES,
        subCategory: SearchSubCategoryEnum.ARTICLE_REPORTS
      });
    });
  }

  communicationsInsightsIncludeTeamBasedCommunities() {
    this.communicationsInsights(true);
  }

  communicationsInsights(includeTeamBasedCommunities) {
    this.hubController.getDiscoverAccessState()
      .then(() => {
        if (this._checkSearchAccessRedirect(SearchCategoryEnum.COMMUNITIES_INSIGHTS)) {
          return;
        }

        this._loadHubPage(COMMUNITIES_INSIGHTS, {
          includeTeamBasedCommunities: Boolean(includeTeamBasedCommunities)
        });
      });
  }

  communicationsInsightsReportsIncludeTeamBasedCommunities(tabName = 'initial') {
    this.communicationsInsightsReports(tabName, true);
  }

  communicationsInsightsReports(tabName, includeTeamBasedCommunities = null) {
    const boolIncludeTeamBasedCommunities = Boolean(includeTeamBasedCommunities);

    this.hubController.getDiscoverAccessState()
      .then(() => {
        if (this._checkSearchAccessRedirect(SearchCategoryEnum.COMMUNITIES_INSIGHTS_REPORTS)) {
          return;
        }

        this._loadHubPage(COMMUNITIES_INSIGHTS_REPORTS, {
          includeTeamBasedCommunities: boolIncludeTeamBasedCommunities,
          currentTabName: tabName
        });
      });
  }

  reportsTopPages() {
    Promise.all([
      this._fetchUserMetadata(),
      this.hubController.getDiscoverAccessState()
    ]).then(() => {
      if (this._checkSearchAccessRedirect(SearchCategoryEnum.ARTICLES, SearchSubCategoryEnum.TOP_PAGES)) {
        return;
      }

      this._loadHubPage(SEARCH, {
        searchCategory: SearchCategoryEnum.ARTICLES,
        subCategory: SearchSubCategoryEnum.TOP_PAGES
      });
    });
  }

  reportsTopKeywords() {
    Promise.all([
      this._fetchUserMetadata(),
      this.hubController.getDiscoverAccessState()
    ]).then(() => {
      if (this._checkSearchAccessRedirect(SearchCategoryEnum.ARTICLES, SearchSubCategoryEnum.TOP_KEYWORDS_IN_SEARCH)) {
        return;
      }

      this._loadHubPage(SEARCH, {
        searchCategory: SearchCategoryEnum.ARTICLES,
        subCategory: SearchSubCategoryEnum.TOP_KEYWORDS_IN_SEARCH
      });
    });
  }

  reportsTopKeywordsEmptySearch() {
    Promise.all([
      this._fetchUserMetadata(),
      this.hubController.getDiscoverAccessState()
    ]).then(() => {
      if (this._checkSearchAccessRedirect(SearchCategoryEnum.ARTICLES, SearchSubCategoryEnum.TOP_KEYWORDS_EMPTY_SEARCH)) {
        return;
      }

      this._loadHubPage(SEARCH, {
        searchCategory: SearchCategoryEnum.ARTICLES,
        subCategory: SearchSubCategoryEnum.TOP_KEYWORDS_EMPTY_SEARCH
      });
    });
  }

  communitiesManagementCommunities(pageNumString) {
    const pageNum = parseInt(pageNumString, 10) || undefined;
    const pageOptions = {
      pageNum
    };

    this._loadHubPageWithData(SearchCategoryEnum.COMMUNITIES_MANAGEMENT, COMMUNITIES, pageOptions);
  }

  communitiesManagementSettings() {
    this._loadHubPageWithData(SearchCategoryEnum.COMMUNITIES_MANAGEMENT, SETTINGS);
  }

  communitiesManagementFilters(filterIDString) {
    const filterId = parseInt(filterIDString, 10) || undefined;
    const pageOptions = {
      filterId
    };
    this._loadHubPageWithData(SearchCategoryEnum.COMMUNITIES_MANAGEMENT, FILTERS, pageOptions);
  }

  communitiesManagementProfanityFilter() {
    this._loadHubPageWithData(SearchCategoryEnum.COMMUNITIES_MANAGEMENT, PROFANITY_FILTER);
  }

  communitiesManagementTags(pageNumString) {
    const pageNum = parseInt(pageNumString, 10) || undefined;
    const pageOptions = {
      pageNum
    };

    this._loadHubPageWithData(SearchCategoryEnum.COMMUNITIES_MANAGEMENT, TAGS, pageOptions);
  }

  communitiesManagementImportHistory(pageNumString) {
    const pageNum = parseInt(pageNumString, 10) || undefined;
    const pageOptions = {
      pageNum
    };

    this._loadHubPageWithData(SearchCategoryEnum.COMMUNITIES_MANAGEMENT, IMPORT_HISTORY, pageOptions);
  }

  communityManagement(communityIdString) {
    const communityId = parseStringId(communityIdString);
    const pageOptions = {
      communityId
    };

    this._loadHubPageWithData(SearchCategoryEnum.COMMUNITIES_MANAGEMENT, COMMUNITY_MANAGEMENT, pageOptions);
  }

  pageInsights(pageId, teamId) {
    const pageOptions = {
      pageId,
      teamId
    };

    this._loadHubPageWithData(SearchCategoryEnum.PAGE_INSIGHTS, PAGE_INSIGHTS, pageOptions);
  }


  // TODO update access check to account for Paths
  _checkSearchAccessRedirect(type, subPage = undefined) {
    const propertyProvider = TenantPropertyProvider.get();
    const dzTenantValue = propertyProvider.getProperty('discoveryZoneEnabled');
    const isDZGuest = this.authUser.isGuestOrSuperuser();
    const dzEnabled = dzTenantValue && (this.hubController.hasSomeDiscoveryZoneCommunityAvailable() || isDZGuest);
    const etEnabled = this.hubController.isExtraTrainingEnabled();
    const isCommsEnabled = propertyProvider.getProperty('communicationsEnabled');

    let isRedirecting = false;

    if (!dzEnabled && !etEnabled) {
      window.app.layout.flash.error(I18n.t('selfDirected.search.notAvailable.primaryMessage'));

      isRedirecting = true;
      this.navigate('hub', {
        trigger: true,
        replace: true
      });

    } else if (type !== SearchCategoryEnum.ARTICLES && isDZGuest) {
      isRedirecting = true;
      this.navigate('hub/search/all/1/', {
        trigger: true,
        replace: true
      });

    } else if (type === SearchCategoryEnum.ARTICLES && !dzEnabled) {
      window.app.layout.flash.error(I18n.t('selfDirected.search.errors.articleSearchNotAvailable'));

      isRedirecting = true;
      this.navigate('hub/search', {
        trigger: true,
        replace: true
      });

    } else if (type === SearchCategoryEnum.ARTICLES
        && (subPage === SearchSubCategoryEnum.ARTICLE_REPORTS
          || subPage === SearchSubCategoryEnum.TOP_PAGES
          || subPage === SearchSubCategoryEnum.TOP_KEYWORDS_IN_SEARCH
          || subPage === SearchSubCategoryEnum.TOP_KEYWORDS_EMPTY_SEARCH)
          && !this._canViewDiscoverReports()) {
      window.app.layout.flash.error(I18n.t('selfDirected.search.errors.articleSearchNotAvailable'));

      isRedirecting = true;
      this.navigate('hub/search', {
        trigger: true,
        replace: true
      });

    } else if (type === SearchCategoryEnum.TOPICS && !etEnabled) {
      window.app.layout.flash.error(I18n.t('selfDirected.search.errors.topicSearchNotAvailable'));

      isRedirecting = true;
      this.navigate('hub/search', {
        trigger: true,
        replace: true
      });

    } else if (type === SearchCategoryEnum.PATHS && !etEnabled) {
      window.app.layout.flash.error(I18n.t('selfDirected.search.notAvailable.primaryMessage'));

      isRedirecting = true;
      this.navigate('hub', {
        trigger: true,
        replace: true
      });

    } else if (type === SearchCategoryEnum.COMMUNITIES_MANAGEMENT && !this.authUser.hasCommunityManagementAccess()) {
      window.app.layout.flash.error(I18n.t('selfDirected.search.notAvailable.primaryMessage'));

      isRedirecting = true;
      this.navigate('hub', {
        trigger: true,
        replace: true
      });

    } else if (type === SearchCategoryEnum.COMMUNITIES_MANAGEMENT && subPage === IMPORT_HISTORY && !isCommsEnabled) {
      window.app.layout.flash.error(I18n.t('selfDirected.search.notAvailable.primaryMessage'));

      isRedirecting = true;
      this.navigate('hub/search/communitiesManagement/communities/1', {
        trigger: true,
        replace: true
      });
    } else if (type === SearchCategoryEnum.COMMUNITIES_INSIGHTS && (!isCommsEnabled || !this.authUser.hasDiscoverReportingAccess())) {
      window.app.layout.flash.error(I18n.t('selfDirected.search.notAvailable.primaryMessage'));

      isRedirecting = true;
      this.navigate('hub/search/all/1/', {
        trigger: true,
        replace: true
      });
    } else if (type === SearchCategoryEnum.COMMUNITIES_INSIGHTS_REPORTS && (!isCommsEnabled || !this.authUser.hasDiscoverReportingAccess())) {
      window.app.layout.flash.error(I18n.t('selfDirected.search.notAvailable.primaryMessage'));

      isRedirecting = true;
      this.navigate('hub/search/all/1/', {
        trigger: true,
        replace: true
      });
    }

    return isRedirecting;
  }

  timelineSearch(paramsString) {
    const {
      id,
      tab,
      searchQuery
    } = getQueryParams(`?${ paramsString }`);

    const targetSearchQuery = searchQuery != null ? decodeURIComponent(searchQuery) : undefined;

    this._loadHubPage(TIMELINE, {
      targetReferenceItemId: id,
      targetTab: tab,
      targetSearchQuery
    });
  }

  conversationalSearch() {
    this._loadHubPage(CONVERSATIONAL_SEARCH);
  }

  _loadHubPageWithData(searchCategory, page, data) {
    this.hubController.getDiscoverAccessState()
      .then(() => {
        if (this._checkSearchAccessRedirect(searchCategory, page)) {
          return;
        }

        this._loadHubPage(page, data);
      });
  }

  _isArticleIdNull(articleIdString) {
    const articleId = parseStringId(articleIdString);

    if (!articleId) {
      window.app.layout.flash.error(I18n.t('selfDirected.articleDetails.errors.noId'));
      this.navigate('hub/search/articles/1/', {
        trigger: true,
        replace: true
      });

      return false;
    }

    return articleId;
  }

  _canViewDiscoverReports() {
    return (this.userMetadata.canViewReports() && !this.authUser.isAdminUser()) || this.authUser.hasDiscoverReportingAccess();
  }

  _fetchUserMetadata() {
    return this.userMetadata.fetch();
  }
}

module.exports = TrainingRouter;
