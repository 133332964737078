import {
  ListItemText as MuiListItemText,
  type ListItemTextProps as MuiListItemTextProps,
  styled
} from '@mui/material';
import {
  type Ref,
  forwardRef
} from 'react';

type AxListItemTextProps = MuiListItemTextProps;

const styledOptions = {
  name: 'AxListItemText'
};

const StyledAxListItemText = styled(MuiListItemText, styledOptions)<AxListItemTextProps>(() => {
  // This component is used by multiple consumers, and each parent has its own default styles.
  // Therefore, we don't include any default styles here.
  return {};
});

export const AxListItemText = forwardRef(({
  children,
  ...otherProps
}: AxListItemTextProps, ref: Ref<HTMLLIElement>) => {
  const ListItemTextProps: AxListItemTextProps = {
    children,
    ...otherProps
  };

  return (
    <StyledAxListItemText { ...ListItemTextProps } ref={ ref }>
      { children }
    </StyledAxListItemText>
  );
});

export default AxListItemText;
