const logging = require('logging');
const $os = require('detectOS');
const LoadTimeLogController = require('LoadTimeLogController');
const { location } = window;

require('focusVisible');
require('@common/configs/backbone/virtual-collection');

const ErrorHandler = require('ErrorHandler');
const Backbone = require('Backbone');
const {
  Application,
  ItemView
} = require('Marionette');

require('@common/prerequisites/assets/stylesheets/resets/normalize.less');
require('@common/vendor/jquery/jquery-ui/jquery-ui-1.8.20.custom.css');
require('@common/vendor/chosen/chosen.css');
require('@training/assets/stylesheets/bootstrap.less');
require('@common/vendor/plainScrollbar/plain-scrollbar.css');
require('@common/vendor/froala-editor-unminified-source/css/froala_style.css');

require('@training/assets/stylesheets/app.less');

const I18n = require('@common/libs/I18n');
const NativeBridge = require('@common/libs/NativeBridge');

const CSSLoaderService = require('@common/services/cssLoader/CSSLoaderService');

const MomentLocaleBundle = require('@common/libs/locale/MomentLocaleBundle');

const ViewControllerFactory = require('@common/libs/UI/controllers/ViewControllerFactory');

const RootLayout = require('@common/components/view/rootLayout/RootLayout');

const BaseApp = require('@training/apps/base/app');
const AuthApp = require('@training/apps/auth/app');
const RedirectingAbortedAuthentication = require('@training/apps/auth/exceptions/RedirectingAbortedAuthentication');

const UpdateApp = require('@common/modules/updates/app');
const UpdateNotificationBuilder = require('@common/modules/updates/UpdateNotificationBuilder');

const SessionContextAwareUpdateNotifier = require('@training/apps/training/SessionContextAwareUpdateNotifier');

const Router = require('@training/apps/training/router');
const GuestRouter = require('@training/apps/training/GuestRouter');
const DeferredRouteDispatcher = require('@training/apps/training/DeferredRouteDispatcher');
const TrainingCSSInjectorPackage = require('./TrainingCSSInjectorPackage');
const PrintService = require('@common/services/print/PrintService');
const TenantPropertyProvider = require('@common/services/TenantPropertyProvider');
const LoadTimeLogHelpers = require('@common/libs/helpers/app/LoadTimeLogHelpers');

const UIKit = require('@training/widgets/UIKit');

const TrainingLayoutAdapter = require('@training/apps/training/TrainingLayoutAdapter');

const SessionController = require('@training/apps/training/controllers/SessionController');
const SuperuserRouter = require('@training/apps/training/SuperuserRouter');

const AccessibleModalView = require('@training/apps/main/views/AccessibleModalView');
const ConfirmDialogView = require('@training/apps/main/views/ConfirmDialogView');
const MsTeamsHelpers = require('@common/libs/helpers/app/MsTeamsHelpers');

const UserType = require('@common/data/enums/UserType');

const DEFAULT_LOCALE = 'EN';

class TrainingBaseApp extends Application {

  onBeforeStart() {
    logging.debug('App before start');

    this.nbChannel = Backbone.Wreqr.radio.channel('nativeBridge');
    this.glChannel = Backbone.Wreqr.radio.channel('global');

    this.viewControllerFactory = new ViewControllerFactory();

    this.initializeLocalBundles();
    this.initializeNativeBridge();
    this.initializePrintService();
    this.initializeRootView();

    CSSLoaderService.registerVersionedFactory( new TrainingCSSInjectorPackage.Factory() );
  }

  initializeLocalBundles() {
    // Register packages that we might need now, this won't load them but just provide
    // pathing information when `setLocale` is eventually called
    I18n.registerBundle(new MomentLocaleBundle());

    // Setup a listener to handle the rest of the dynamic injection if required
    // and swap the stylesheets as required
    I18n.listenForLocaleChange(() => {
      document.title = I18n.t('menu.apps.training');
    });
  }

  initializeNativeBridge() {
    // The wrapper calls the native bridge by window.app.nativeBridge that's needs
    // to be accessible through the window namespace.  Because of this, we
    // must attach nativeBridge to window.app :/
    if (window.app == null) {
      window.app = {};
    }

    window.app.nativeBridge = new NativeBridge();
  }

  initializeRootView() {
    this.rootLayout = new RootLayout();

    this.listenToOnce(this.rootLayout, 'childview:show', () => {
      this.nbChannel.vent.trigger('appHasLoaded');
      LoadTimeLogController.stop('APP_HAS_LOADED');
      LoadTimeLogHelpers.getAdditionalLogFields()
        .then((additionalFields) => {
          LoadTimeLogController.sendLogs(additionalFields);
        });
    });

    $(window).on('unload', () => {
      logging.debug('Window Unload - destroying view hierarchy!');
      if (app.layout) {
        window.app.layout.togglePageSpinnerService(false);
      }

      this.stopRouting();
      MsTeamsHelpers.saveUrlState();

      this.rootLayout.destroy();
    });
  }

  onStart() {
    logging.debug('App start');
    MsTeamsHelpers.loadUrlState();
    const routeDispatcher = new DeferredRouteDispatcher();

    const promises = [
      // We reflect since we can survive failure
      this.setLocaleAsync(DEFAULT_LOCALE, true)
        .catch((err) => {
          return logging.error('[locale-async] For some reason, locale could not be set right', err);
        })
        .reflect(),

      this.injectApplicationStylesheetsAsync(DEFAULT_LOCALE)
    ];

    Promise.all(promises)
      .then(this.initializeTrainingModules.bind(this, routeDispatcher))
      .then(() => {
        return this.auth.checkSession()
          .then(() => {
            // This is reflected since locale changes can fail and yet the application can go
            return this.setUserLocaleAsync().reflect();
          })
          .then(this.startApplication.bind(this))
          .then(this.checkOnTheClockStatus.bind(this))
          .then(() => {
            routeDispatcher.setReady();
          });
      })
      .catch(RedirectingAbortedAuthentication, (err) => {
        logging.info(
          'The authentication flow was interrupted due to an external redirect.'
        );

        // Ehe error has more info about the redirect
        logging.info(err);

        // We do nothing here so that the error can fall through
      })
      .catch((err) => {
        logging.error(
          'The application bootstrap process has somehow failed completely or partially. This is a serious problem.'
          , err
        );
      });
  }

  initializeTrainingModules(routeDispatcher) {
    logging.debug('App boostrap EN loaded');

    // Create base app
    this.base = new BaseApp();

    // Create auth app
    this.auth = new AuthApp({ routeDispatcher });

    this.module('react', {
      moduleClass: require('@common/modules/react').ReactModule,
      startWithParent: false,
      el: this.rootLayout.ui.reactRoot[0],
      contextState: {
        authUser: this.auth.session.user,
        tenant: this.auth.session._tenant,
        tenantProperties: TenantPropertyProvider.get(),
        timeLogController: this.base.timeLogController,
        defaultDialogProps: {
          container: () => {
            return document.getElementById('page-view');
          }
        }
      }
    });

    this.module('branding', {
      moduleClass: require('@common/modules/branding/BrandingModule'),
      fileName: $os.mobile ? 'mobilebranding' : 'branding',
      session: this.auth.session
    });

    this.module('userCoach', {
      moduleClass: require('@common/modules/userCoach/UserCoachModule'),
      user: this.auth.session.user
    });

    window.app.layout = new TrainingLayoutAdapter({
      viewControllerFactory: this.viewControllerFactory
    });

    // Setup a listener to handle the rest of the dynamic injection if required
    // and swap the stylesheets as required
    I18n.listenForLocaleChange(this.injectApplicationStylesheetsAsync.bind(this));
  }

  startApplication() {
    logging.debug('App boostrap startApplication');

    window.app.sessionController = new SessionController();

    window.app.layout.initializeHeaderSettings();
    window.app.layout.initializeTainingPageView();
    window.app.layout.registerBonusCharacterUpdater();
    window.app.layout.registerDownloadLinkHandler();

    // Create routers and dispatch routes as well as init and show nav menu when not on auth page
    if ((/auth\.html$/).test(location.pathname)) {
      logging.info('auth.html loaded, loading auth router');
      this.auth.createRouter();
    } else {
      window.app.layout.initializeMenu({
        sessionController: window.app.sessionController,
        authSession: this.auth.session
      });
      if (this.auth.session.user.isGuestOrSuperuser()) {
        this.router = this.auth.session.user.isSuperuser() ? new SuperuserRouter() : new GuestRouter();
      } else {
        this.router = new Router(app.sessionController);
      }

      this.router.on('all', (route) => {
        return logging.debug(`Navigated to training route: \`${ route }\``);
      });
    }

    this.registerLogoClickHandler();

    this.module('react').start();

    this.installUpdateSystem();

    window.app.layout.show(this.rootLayout.getRegion('main'));
    window.app.layout.togglePageSpinnerService(true);

    this.startRouting();

    MsTeamsHelpers.keepSessionAlive(this.auth.session.user.isLoggedIn());
  }

  registerLogoClickHandler() {
    this.glChannel.commands.setHandler('app:logoClicked', () => {
      if (!this.auth.session.user.isLoggedIn()) {
        return;
      }

      if (UserType.isDZGuestUser(this.auth.session.user.getUserType())) {
        Backbone.history.navigate('#hub/search/all/1/', true);
      } else if (window.app.sessionController.isHubMenuEnabled()) {
        Backbone.history.navigate('#hub', true);
      } else {
        Backbone.history.navigate('#daily-activities', true);
      }
    });
  }

  checkOnTheClockStatus() {
    if (this.auth.session.user.isLoggedIn() && !this.auth.session.user.get('isOnTheClock')) {
      return new Promise((resolve) => {
        const modalView = new AccessibleModalView({
          id: 'modalview',
          className: 'modal confirm-dialog-view modal--s'
        });

        const modalChildView = new ConfirmDialogView({
          title: I18n.t('general.warning'),
          confirmationText: I18n.t('onTheClock.login.warning.message'),
          buttonConfig: [{
            type: 'customText',
            text: I18n.t('general.ok'),
            className: 'white',
            onClick: () => {
              window.app.layout.dismissModal(resolve);
            }
          }]
        });

        window.app.layout.setView(new ItemView({ template: false }), UIKit.View.Transitions.NONE);
        window.app.layout.presentModal(modalView, {
          closeClick: false
        });
        modalView.setSubviewIn(modalChildView, { transition: UIKit.View.Transitions.NONE });
      });
    }

    return undefined;
  }

  initializePrintService() {
    this.printService = new PrintService({
      popupBlockedHandler: (message) => {
        window.app.layout.flash.error(message);
      }
    });

    // NOTE: DZ is my guide here - in KnowledgeBaseApp a handler is set and never removed. Hopefully not a problem
    this.glChannel.commands.setHandler('app:print', this.printService.print);
  }

  installUpdateSystem() {
    const session = window.app.sessionController.session;

    const handlers = new UpdateNotificationBuilder()
      .withDefaultSilentUpdate()
      .withDecorator((wrapper) => {
        return new SessionContextAwareUpdateNotifier(session, wrapper);
      })
      .build();

    this.module('application-update-monitor', {
      moduleClass: UpdateApp,
      handlers
    });
  }

  setUserLocaleAsync() {
    logging.debug('App boostrap auth process done');

    let langCode = this.auth.session.user.get('language') || I18n.getBrowserLocale();
    const langCodeNoLocale = langCode.split('-')[0];
    const tenantLanguages = TenantPropertyProvider.get().getProperty('languages');

    if (!tenantLanguages.includes(langCode) && !tenantLanguages.includes(langCodeNoLocale)) {
      langCode = TenantPropertyProvider.get().getProperty('defaultLanguage');
    }

    return this.setLocaleAsync(langCode);
  }

  setLocaleAsync(localeCode, defaultLocale = false) {
    const label = defaultLocale ? 'LOCALE' : 'LOCALE_DEFAULT';
    LoadTimeLogController.start(label, {code: localeCode});

    return new Promise((resolve, reject) => {
      I18n.setLocale(localeCode, {
        success: resolve,
        failure: reject
      });
    }).finally(() => {
      LoadTimeLogController.stop(label, {code: localeCode});
    });
  }

  injectApplicationStylesheetsAsync(locale) {
    LoadTimeLogController.start('CSS_INJECT');
    return CSSLoaderService.load( TrainingCSSInjectorPackage.KEY, {
      locale,
      pivot: '#branding'
    } ).then(() => {
      LoadTimeLogController.stop('CSS_INJECT');
    });
  }

  startRouting() {
    if (!Backbone.History.started) {
      logging.debug('App boostrap starting history');
      Backbone.history.start({ silent: true });

      // Set a history.stop callback for the event of a
      // javascript error
      ErrorHandler.setOnErrorCallback(Backbone.history.stop);
    }
  }

  stopRouting() {
    if (Backbone.History.started) {
      Backbone.history.stop();
      logging.info('Router stopped');
    }
  }

}

module.exports = TrainingBaseApp;
