import {
  ListItemIcon as MuiListItemIcon,
  type ListItemIconProps as MuiListItemIconProps,
  styled
} from '@mui/material';
import {
  type Ref,
  forwardRef
} from 'react';

type AxListItemIconProps = MuiListItemIconProps;

const styledOptions = {
  name: 'AxListItemIcon'
};

const StyledAxListItemIcon = styled(MuiListItemIcon, styledOptions)<AxListItemIconProps>(() => {
  // This component is used by multiple consumers, and each parent has its own default styles.
  // Therefore, we don't include any default styles here.
  return {};
});

export const AxListItemIcon = forwardRef(({
  children,
  ...otherProps
}: AxListItemIconProps, ref: Ref<HTMLLIElement>) => {
  const ListItemIconProps: AxListItemIconProps = {
    children,
    ...otherProps
  };

  return (
    <StyledAxListItemIcon { ...ListItemIconProps } ref={ ref }>
      { children }
    </StyledAxListItemIcon>
  );
});

export default AxListItemIcon;
