import {
  AxIcon,
  type BaseIconCustomProps,
  type IconNames
} from '@common/modules/react/themes/components';
import { getIconSizeStyles } from '@common/modules/react/themes/components/data-display/AxIcon/utils';
import {
  Fab as MuiFab,
  styled,
  useTheme,
  type FabTypeMap as MuiFabTypeMap
} from '@mui/material';
import { type OverrideProps as MuiOverrideProps } from '@mui/material/OverridableComponent';
import {
  forwardRef,
  type ElementType,
  type Ref
} from 'react';

type AxFabCustomProps = {
  component?: React.ElementType // Reset to optional instead of MuiOverridableComponent default of required
  /**
   * The name of the icomoon class icon to display. If you use it, it will match the Ui Kit spec automatically.
   */
  iconName?: IconNames,
  /**
   * You can use composition to add custom content to the button.
   */
  children?: React.ReactNode,
};

type PropsToOmit = 'centerRipple'
| 'color'
| 'disableFocusRipple'
| 'disableRipple'
| 'disableTouchRipple'
| 'focusRipple'
| 'TouchRippleProps'
| 'touchRippleRef'
| 'variant';

export type AxFabTypeMap<P = object, D extends React.ElementType = 'button'> = MuiFabTypeMap<P & AxFabCustomProps, D>;

export type AxFabProps<
  D extends ElementType = AxFabTypeMap['defaultComponent'],
  P = object,
> = Omit<MuiOverrideProps<AxFabTypeMap<P & AxFabCustomProps, D>, D>, PropsToOmit>;

const StyledAxFab = styled(MuiFab, { name: 'AxFab' })<AxFabProps>(() => {
  return {};
});

/**
 * A floating action button component.
 *
 * ### Links
 * - [UiKit Figma](https://www.figma.com/design/mAGiAo8UwCUfdpW9SNdswW/%F0%9F%A7%B0-UIKit?node-id=12887-22003&m=dev)
 * - [MUI Fab API](https://mui.com/material-ui/react-floating-action-button/)
 */
export const AxFab = forwardRef(({
  children,
  iconName = 'icon-plus',
  ...otherProps
}: AxFabProps, ref: Ref<HTMLButtonElement>) => {
  const buttonProps = {
    children,
    ...otherProps
  };

  const {
    buttonStyles,
    iconStyles
  } = useAxFabStyles(buttonProps.size);

  return (
    <StyledAxFab
      disableFocusRipple
      ref={ ref }
      disableRipple
      disableTouchRipple
      sx={ buttonStyles }
      { ...buttonProps }
    >
      { children ?? <AxIcon iconName={ iconName }
        sx={{
          fontSize: iconStyles.fontSize
        }} /> }
    </StyledAxFab>
  );
});

export default AxFab;

export const useAxFabStyles = (size: AxFabProps['size'] = 'large') => {
  const theme = useTheme();

  const sizeStyles = {
    small: {
      height: theme.uiKit.spacingL,
      width: theme.uiKit.spacingL,
      minHeight: theme.uiKit.spacingL
    },
    medium: {
      height: theme.uiKit.spacingXL,
      width: theme.uiKit.spacingXL,
      minHeight: theme.uiKit.spacingXL
    },
    large: {
      height: theme.uiKit.spacingXXL,
      width: theme.uiKit.spacingXXL,
      minHeight: theme.uiKit.spacingXXL
    }
  };

  const iconSizeMap = {
    small: 'small',
    medium: 'small',
    large: 'medium'
  };

  const buttonStyles = {
    backgroundColor: theme.uiKit.colorWhite,
    boxShadow: theme.uiKit.boxShadowS,
    ...sizeStyles[size || 'medium'],
    '&:hover': {
      backgroundColor: theme.uiKit.colorGrey10
    },
    '&.Mui-disabled': {
      color: theme.uiKit.colorGrey50,
      fontWeight: theme.uiKit.fontWeightBold
    }
  };


  return {
    buttonStyles: buttonStyles,
    iconStyles: {
      fontSize: getIconSizeStyles(iconSizeMap[size] as BaseIconCustomProps['fontSize'], theme).fontSize
    }
  };
};
